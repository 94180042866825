import Vue from 'vue';
import { getUpgradeList, deleteUpgradePlan } from '@/api/basic';
import basicHeader from '@/components/backStageHeader';
import EButton from '@/components/button/index.vue';
import ETable from '@/components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import { Tooltip } from 'element-ui';
export default {
  name: 'Upgrade',
  props: {},
  data() {
    return {
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.getTableData,
      },
      mySubscriber: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          label: '升年级学年',
          prop: 'fromSchYearName',
        },
        {
          label: '升年级至',
          prop: 'toSchYearName',
        },
        {
          label: '升年级状态',
          component: Vue.extend({
            computed: {
              componentStyles() {
                const status = this.$attrs.row.upgradeStatus;
                return {
                  backgroundColor:
                    status == -1 ? '#FFF0F0' : status == 0 ? '#FFFBF4' : status == 1 ? '#EFFFFB' : '#F0F3F7',
                  borderColor: status == -1 ? '#F26363' : status == 0 ? '#FFBB2C' : status == 1 ? '#38C3A1' : '#DEDEDE',
                  color: status == -1 ? '#F26363' : status == 0 ? '#FFBB2C' : status == 1 ? '#38C3A1' : '#808080',
                  height: '24px',
                  maxWidth: '170px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  borderRadius: '80px',
                };
              },
            },
            render(h) {
              return h(
                'div',
                {
                  class: `border border-1 border-solid mx-auto inline-block truncate`,
                  style: this.componentStyles,
                },
                `${
                  this.$attrs.row.upgradeStatus == -1
                    ? `失败(${this.$attrs.row.errMsg})`
                    : this.$attrs.row.upgradeStatus == 0
                    ? '未开始'
                    : this.$attrs.row.upgradeStatus == 1
                    ? '进行中'
                    : '已完成'
                }`
              );
            },
          }),
        },
        {
          label: '升年级时间',
          prop: 'upgradeTime',
        },
        {
          label: '最新生成数据时间',
          prop: 'generateTime',
        },
        {
          label: '升年级对象',
          className: 'upgradePlanObjs',
          hideTooltip: true,
          component: Vue.extend({
            components: {
              'el-tooltip': Tooltip,
            },
            render(h) {
              const listItems = this.$attrs.row.upgradePlanObjs?.map((item, index) => {
                return h(
                  'el-tooltip',
                  {
                    props: {
                      content: item.gradeName,
                      placement: 'top',
                    },
                  },
                  [
                    h(
                      'li',
                      {
                        style: {
                          borderTop: index > 0 ? `1px solid #f0f3f7` : '',
                          lineHeight: '36px',
                          height: '40px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        },
                        class: `truncate`,
                      },
                      item.gradeName
                    ),
                  ]
                );
              });
              return h('div', [h('ul', listItems)]);
            },
          }),
        },
        {
          label: '升年级方式',
          className: 'upgradePlanObjs',
          hideTooltip: true,
          component: Vue.extend({
            components: {
              'el-tooltip': Tooltip,
            },
            render(h) {
              const listItems = this.$attrs.row.upgradePlanObjs?.map((item, index) => {
                return h(
                  'el-tooltip',
                  {
                    props: {
                      content: item.upgradeWayName,
                      placement: 'top',
                    },
                  },
                  [
                    h(
                      'li',
                      {
                        style: {
                          borderTop: index > 0 ? `1px solid #f0f3f7` : '',
                          lineHeight: '36px',
                          height: '40px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        },
                        class: `truncate`,
                      },
                      item.upgradeWayName
                    ),
                  ]
                );
              });
              return h('div', [h('ul', listItems)]);
            },
          }),
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '配置',
                disabled: (row) => row.upgradeStatus == 1 || row.upgradeStatus == 2,
                handle: (row) => this.goSettings(row),
              },
              {
                text: '删除',
                disabled: (row) => row.upgradeStatus == 1 || row.upgradeStatus == 2,
                handle: (row) => this.handleDelete(row),
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
    canAddPlan() {
      return !this.table?.list.length || this.table.list[0]?.upgradeStatus === 2;
    },
  },
  components: {
    basicHeader,
    EButton,
    ETable,
    Tooltip,
  },
  created() {
    this.getTableData();
    this.mySubscriber = PubSub.subscribe('updateList', this.getTableData);
  },
  beforeDestroy() {
    PubSub.unsubscribe(this.mySubscriber);
  },
  methods: {
    getTableData() {
      getUpgradeList({
        size: this.pagination.pageSize,
        current: this.pagination.page,
      }).then((res) => {
        if (res.status === 0) {
          this.table.list = res.result.data;
          this.pagination.total = +res.result.recordCount;
        }
      });
    },
    handleDelete(row) {
      const h = this.$createElement;
      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, `确认要删除【${row.upgradeTime ?? row.fromSchYearName}】的升年级计划吗？`),
          h('p', { class: 'T5-2' }, '删除后不再执行升年级计划'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          deleteUpgradePlan({
            id: row.id,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            this.getTableData();
          });
        })
        .catch(() => {});
    },
    goSettings(row) {
      this.$router.push({
        path: '/back-stage/basic-data/upgrade/settings',
        query: {
          upgradePlanId: row?.id,
        },
      });
    },
  },
};
